import { useState, useEffect } from 'react';
export function src(imageUrl, width, height) {
  if (!imageUrl) return;
  return imageUrl.replace('/w/', "/".concat(width, "/")).replace('/h/', "/".concat(height, "/"));
}
export function srcSet(imageUrl, _ref) {
  var width = _ref.width,
      height = _ref.height;
  if (!imageUrl) return;
  return "\n    ".concat(src(imageUrl, width, height), " 1x,\n    ").concat(src(imageUrl, 2 * width, 2 * height), " 2x\n  ");
}
export function useDimensions(ref) {
  useEffect(function () {
    if (!ref.current || !ref.current.scrollWidth || !ref.current.scrollHeight || dimensions) return;
    setDimensions({
      width: ref.current.scrollWidth,
      height: ref.current.scrollHeight
    });
  }, [ref.current]);

  var _useState = useState(),
      dimensions = _useState[0],
      setDimensions = _useState[1];

  return dimensions;
}
export function isImage(filename) {
  return filename === null || filename === void 0 ? void 0 : filename.match(/\.(jpg|jpeg|png|gif|webp)$/i);
}