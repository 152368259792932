export function getAdvertisementData(metadata) {
  if (!metadata) return;
  var name = metadata.match(/advertorialName: (.*)/);
  var url = metadata.match(/advertorialUrl: (.*)/);
  if (name && url) return [name[1], url[1]];
}
export function getContentReference(metadata) {
  var referenceData = metadata && metadata.match(/\[.+\)/);
  return referenceData && referenceData[0].split(', ').map(function (item) {
    var data = item.match(/\[(.+?)]\((.+?)\)/);
    if (data) return {
      title: data[1],
      link: data[2]
    };
  }).filter(Boolean);
}